export default {
  name: 'magic-cube',
  label: {
    title: '魔方',
    icon: require('@/assets/icon-component-label_MagicCube.png'),
    limit: 100
  },
  sort: 3,
  data: {
    blockList: [],
  }
}
