<template>
  <div class="image-adv-selector">
    <div class="title-selector">
      <div class="title">添加切换列表</div>
      <p class="tips">鼠标拖拽调整列表顺序</p>
    </div>
    <div class="body-selector">
      <draggable
        v-if="Array.isArray(links)"
        v-bind="dragOption"
        :list="links"
        :disabled="!sort"
        prevent-on-filter
        animation="200"
        filter=".ignore-drag"
        class="link-editor__list"
        :class="{ 'is-sort': sort }"
      >
        <transition-group>
          <div v-for="(item, index) in links" :key="index" class="link-editor__item">
            <div class="link-editor__item-wrap">
              <div class="link-editor__item-info">
                <div class="item-info-label">
                  <p class="title-item-info">标题</p>
                </div>
                <div class="item-info-value">
                  <el-input v-model="item.label" :placeholder="titlePlaceholder" :disabled="item.disabled" size="mini" class="ignore-drag" />
                </div>
              </div>
            </div>
            <i v-if="!item.disabled" class="ri-close-circle-fill del-icon" @click="handleDeleteItem(item, index)"></i>
          </div>
        </transition-group>
      </draggable>
    </div>
    <div class="link-editor__add" @click="handleAddItem">
      <i class="ri-add-line link-editor__add-icon"></i>
      <span>添加</span>
    </div>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import { mapGetters } from 'vuex'
export default {
  name: 'TabsAdvSelector',
  components: { Draggable },
  props: {
    // 是否可以排序
    sort: {
      type: Boolean,
      required: false,
      default: true,
    },
    // 标题输入框占位
    titlePlaceholder: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      links: [],
      dragOption: {
        sort: true,
      },
    }
  },
  computed: {
    ...mapGetters(['activated', 'accessories', 'previews']),
  },
  mounted() {
    this.links = this.$$FormData.tabsList
  },
  methods: {
    // 删除
    handleDeleteItem(item, index) {
      this.links.splice(index, 1)
      // this.$emit('update:links', this.links)
    },
    // 添加
    handleAddItem() {
      if (!this.links) this.links = []
      this.links.push(this.handleMakeItem())
      // this.$emit('update', this.links)
    },
    // 产生一个item
    handleMakeItem() {
      const item = {
        __key: this.$$Foundation.uuid(),
        label: '',
      }
      return item
    },
  },
  watch: {
    links: {
      handler(n) {
        let list = n.map((item) => {
          return {
            label: item.label,
            value: item.label,
          }
        })
        this.$$ActivatedPreview.settings.tabs_active.options = list
        this.$$ActivatedPreview.tabs_active = list[0] && list[0].label
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.image-adv-selector {
  .title-selector {
    padding: 12px 16px;
    box-sizing: border-box;
    background-color: #ffffff;
    .title {
      color: #323233;
      font-size: 14px;
    }
    .tips {
      color: #969799;
      font-size: 12px;
      line-height: 18px;
      margin-top: 10px;
    }
  }
  .body-selector {
    padding: 12px 16px;
    background-color: #f7f8fa;
    overflow: hidden;
  }
  .del-icon {
    display: none;
    position: absolute;
    top: -10px;
    right: -10px;
    color: #bbbbbb;
    font-size: 20px;
    background-color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
  }
  .link-editor__item-wrap {
    display: flex;
    align-items: center;
    padding: 6px 0;
    border-radius: 2px;
    overflow: hidden;
  }
  .link-editor__list {
    &.is-sort .link-editor__item {
      cursor: move;
    }
    .link-editor__item {
      position: relative;
      box-sizing: border-box;
      background-color: #ffffff;
      box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
      user-select: none;
      padding: 0 12px;
      &.hotarea {
        padding: 0;
        .link-editor__item-wrap {
          padding: 0;
        }
      }
      & + .link-editor__item {
        margin-top: 12px;
      }
      &:hover .del-icon {
        display: block;
      }
      .del-icon {
        display: none;
        position: absolute;
        top: -10px;
        right: -10px;
        color: #bbbbbb;
        font-size: 20px;
        background-color: #ffffff;
        border-radius: 50%;
        cursor: pointer;
      }
      .link-editor__item-wrap {
        display: flex;
        align-items: center;
        padding: 6px 0;
        border-radius: 2px;
        overflow: hidden;
      }
    }
    .link-editor__item-image {
      flex-shrink: 0;
      margin-right: 12px;
    }
    .link-editor__item-info {
      flex: 1;
      display: flex;
      .item-info-label {
        flex-shrink: 0;
        margin-right: 16px;
        .title-item-info {
          min-height: 44px;
          color: #969799;
          font-size: 14px;
          line-height: 44px;
          white-space: nowrap;
        }
      }
      .item-info-value {
        flex: 1;
        & > div {
          display: flex;
          align-items: center;
          min-height: 44px;
        }
      }
    }
  }
  .link-editor__add {
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
    padding: 9px 16px;
    border: 1px solid #155bd4;
    border-radius: 2px;
    color: #155bd4;
    background-color: #ffffff;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    user-select: none;
    transition: all ease 0.2s;
    &:hover {
      background-color: rgba(#155bd4, 0.1);
    }
    .link-editor__add-icon {
      margin-right: 5px;
    }
  }
}
</style>
