export default {
  name: 'image-sudoku',
  label: {
    title: '图片宫格',
    icon: require('@/assets/icon-component-label_ImageSudoku.svg'),
    limit: -1
  },
  sort: 6,
  data: {
    // 列列表
    columnList: []
  }
}
