import dayjs from 'dayjs'

/**
 * 文件单位转换
 * 默认传入KiB
 * @param value
 * @param si
 * @returns {string}
 */
export function bytesUnit(value, si) {
  if (!value) return '0 kB'
  if (value) value *= 1024
  let thresh = si ? 1024 : 1000
  if (Math.abs(value) < thresh) {
    return value + ' B'
  }
  let units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  do {
    value /= thresh
    ++u;
  } while (Math.abs(value) >= thresh && u < units.length - 1)
  return value.toFixed(1) + ' ' + units[u]
}

/**
 * 时间戳转字符串
 * @param time
 * @param format
 * @returns {string}
 */
export function timestampToDate(time, format = 'YYYY-MM-DD') {
  return dayjs.unix(time).format(format)
}
