export const SettingTypes =  {
  custom: 'custom',
  input: 'input',
  boolean: 'boolean',
  radio_button: 'radio-button',
  color_picker: 'color-picker',
  switch: 'switch',
  slider: 'slider',
  select: 'select'
}

export default SettingTypes
