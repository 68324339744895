<template>
  <div :id="editor_id" type="text/plain"></div>
</template>

<script>
import toolbars from './ueditor-toolbars'
export default {
  name: 'UEditor',
  props: {
    value: String
  },
  model: { event: 'update', prop: 'value' },
  data() {
    return {
      ueditor: '',
      content: '',
      ready: false
    }
  },
  computed: {
    editor_id() {
      return 'ueditor_' + this['_uid']
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: 'handleDefaultChange'
    }
  },
  mounted() {
    this.ueditor = window.UE.getEditor(this.editor_id, {
      BaseUrl: '',
      UEDITOR_HOME_URL: '/ueditor/',
      toolbars,
      wordCount: false,
      autoHeightEnabled: false,
      enableAutoSave: false,
      catchRemoteImageEnable: false
    })
    this.ueditor.ready(this.handleEditorReady)
  },
  methods: {
    /**
     * UEditor准备好了
     */
    handleEditorReady() {
      this.ready = true
      const { ueditor } = this
      if (this.value) {
        ueditor.setContent(this.value)
      }
      const body = ueditor.iframe.contentWindow.document.body
      const mutationObserver = new window.MutationObserver(this.handleEditorContentChange)
      mutationObserver.observe(body, {
        attributes: true,
        childList: true,
        characterData: true,
        subtree: true
      })
    },
    /**
     * UEditor内容发生改变
     */
    handleEditorContentChange() {
      this.content = this.ueditor.getContent()
      this.$emit('update', this.content)
    },
    /**
     * 默认值发生改变
     * @param newVal
     */
    handleDefaultChange(newVal) {
      if (!this.ready || newVal === this.content) return
      this.ueditor.setContent(newVal)
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ {
  .edui-editor-iframeholder {
    height: calc(100vh - 470px) !important;
  }
}
</style>
