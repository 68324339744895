import SettingTypes from '@/utils/setting-types'

export default{
 name: 'tabs',
 decor_type: 'shop',
 label: {
    title: '标签栏',
    icon: require('@/assets/icon-component-label_ImageAdv.png'),
    limit: 10
  },
  sort: 15,
  data:{
    tabsList:[{label:'商品',value: '商品'},{label:'项目',value: '项目'}],
      // 选中
    tabs_active:'商品',
    is_scroll:false,
  },
  settings:{
    is_scroll:{
      type: SettingTypes.radio_button,
      label: '模式选择',
      options:[{label:'非滚动',value: false},{label:'滚动',value: true}],
    },
    tabs_active:{
      type: SettingTypes.radio_button,
      label: '默认选中列表',
      options:[{label:'商品',value:"商品"}],
    },
    tabs_list:{
       type: SettingTypes.custom,
       component_name: 'TabsAdvSelector',
    },
  }
}