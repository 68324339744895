export default {
  name: 'seckill',
  label: {
    title: '秒杀模块',
    icon: require('@/assets/icon-component-label_Seckill.svg'),
    limit: 1
  },
  decor_type: 'admin',
  sort: 998
}
