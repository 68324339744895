import Vue from 'vue'
import ElementUI from 'element-ui'
import 'remixicon/fonts/remixicon.css'
import 'element-ui/lib/theme-chalk/index.css'
import 'swiper/css/swiper.min.css'
import store from './store'
import mixins from '@/utils/mixins'
import * as Filters from '@/utils/filters'
import App from './App.vue'
import '@/styles/common.scss'
import UIComponents from 'ui-components'
import settingMixins from '@/components/setting/mixins'
import { clientType } from '@/utils/decor-types'
import VueAwesomeSwiper from 'vue-awesome-swiper'

Vue.mixin(mixins)
Object.keys(Filters).forEach(key => Vue.filter(key, Filters[key]))
Vue.config.productionTip = false
Vue.use(ElementUI, { size: 'small' })
Vue.use(UIComponents)
Vue.use(VueAwesomeSwiper)

// 自动注册全局组件
const requireComponents = require['context']('./components', true, /[A-Za-z]\w+\.(vue)$/)
requireComponents.keys().forEach(async fileName => {
  // 对不相关的客户端类型组件进行拦截
  // 例如当前装修的是pc类型，那么遇到文件路径里有/mobile/的，就不进行注册
  if (fileName.indexOf(clientType === 'pc' ? '/mobile/' : '/pc/') !== -1) return
  let componentConfig = await requireComponents(fileName)
  componentConfig = componentConfig['default']
  // 设置组件需要用到settingMixins
  if (fileName.indexOf('/setting/') !== -1) {
    componentConfig.mixins = [settingMixins]
    componentConfig.name = `Setting${componentConfig.name}`
  }
  Vue.component(componentConfig.name, componentConfig)
})

new Vue({
  render: h => h(App),
  store
}).$mount('#app')
